interface DateCircleProps {
  onClick: (val: string) => void;
  day: string;
  isActive: boolean;
  isSelected: boolean;
  isCurrent: boolean;
  isDisabled?: boolean;
}

export const DateCircle = ({
  onClick,
  day,
  isActive,
  isSelected,
  isCurrent,
  isDisabled,
}: DateCircleProps) => {
  return (
    <div
      onClick={() => {
        onClick(day);
      }}
      className={`justify-self-center items-center text-center text-textMain p-2 w-10 h-10 rounded-full relative cursor-pointer lg:hover:opacity-70 
      ${isCurrent ? " bg-graySecond text-textMain" : ""} 
      ${isSelected ? "!bg-blueMain !text-white" : ""}
      ${isActive && !isDisabled ? "bg-[#DCFAE6]" : ""} 
      ${isDisabled ? "text-textTertiary" : ""}
      `}
    >
      {day}
      {isActive && (
        <div
          className={`h-[5px] w-[5px] ${
            isSelected ? "bg-white" : isDisabled ? "bg-textTertiary" : "bg-greenMain"
          } absolute bottom-1 left-[45%] rounded-full`}
        ></div>
      )}
    </div>
  );
};
