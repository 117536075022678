import Select, {
  GroupBase,
  OptionsOrGroups,
  PropsValue,
  SingleValue,
} from "react-select";

interface DropDownInputProps {
  value: PropsValue<{ value: number | null; label: string | null }> | undefined;
  label: string;
  placeholder: string;
  onChange: (
    val: SingleValue<{
      value: number | null;
      label: string | null;
    }>
  ) => void;
  options:
    | OptionsOrGroups<
        {
          value: number | null;
          label: string | null;
        },
        GroupBase<{
          value: number | null;
          label: string | null;
        }>
      >
    | undefined;
  isError: boolean;
  isLoading: boolean;
  errorMessage: string;
}

export const DropDownInput = ({
  value,
  onChange,
  label,
  placeholder,
  options,
  errorMessage,
  isError,
  isLoading,
}: DropDownInputProps) => {
  return (
    <div className="w-full">
      <label className="form-control w-full">
        <div className="label pt-0">
          <span className="label-text">{label}</span>
        </div>
        <Select
          noOptionsMessage={() => "Brak opcji"}
          loadingMessage={() => "Pobieranie opcji ..."}
          isLoading={isLoading}
          value={value}
          onChange={(
            newValue: SingleValue<{
              value: number | null;
              label: string | null;
            }>
          ) => {
            onChange(newValue);
          }}
          options={options}
          placeholder={placeholder}
          styles={{
            control: (base, state) => ({
              ...base,
              height: 44,
              borderRadius: 8,
              boxShadow:
                state.isFocused && isError
                  ? "0px 0px 0px 4px #F044383D, 0px 1px 2px 0px #1018280D"
                  : state.isFocused
                  ? "0px 0px 0px 4px #0CDB4E3D, 0px 1px 2px 0px #1018280D"
                  : "",
              borderColor: isError
                ? "#FDA29B"
                : state.isFocused
                ? "#03BD3F"
                : base.borderColor,
              "&:hover": {
                boxShadow:
                  state.isFocused && isError
                    ? "0px 0px 0px 4px #F044383D, 0px 1px 2px 0px #1018280D"
                    : state.isFocused
                    ? "0px 0px 0px 4px #0CDB4E3D, 0px 1px 2px 0px #1018280D"
                    : base.borderColor,
              },
            }),
            option: (base, state) => ({
              ...base,
              paddingBottom: 10,
              marginBottom: 10,
              backgroundColor: state.isSelected ? "#03BD3F" : base.backgroundColor,
            }),
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </label>
      {isError && <div className="text-sm text-redMain mt-2">{errorMessage}</div>}
    </div>
  );
};
