import { useQuery } from "@tanstack/react-query";
import moment, { Moment } from "moment";
import get from "lodash.get";
import { useState } from "react";

import { Button } from "../../components/buttons/Button";
import { fetchCalendarData } from "../../services";
import { Calendar } from "../../components/calendar/Calendar";
import { BtnArrowRight } from "../../assets/icons";
import { getDateObject } from "../../utils";
import { FormData } from "../../types";

interface DatesStepProps {
  setStep: () => void;
  setStepFormData: (key: string, val: any) => void;
  formData: FormData;
}

export const DatesStep = ({ setStep, setStepFormData, formData }: DatesStepProps) => {
  const { service, vehiclePlates, vehicleType, selectedDeposit } = formData;

  const [selectedDate, setSelectedDate] = useState<Moment | undefined>(
    formData.selectedDate === undefined
      ? moment()
      : moment(formData.selectedDate, '"DD-MM-YYYY HH:mm"')
  );
  const [selectedDateError, setSelectedDateError] = useState(false);

  const { data: calendarData, ...calendarDataOther } = useQuery<{
    data: { Terminy: string[]; Daty: { Data: string; Dzien: string; Tydzien: number }[] };
  }>({
    queryKey: ["calendar", { vehiclePlates, vehicleType, service, selectedDeposit }],
    queryFn: () =>
      fetchCalendarData(
        vehiclePlates,
        vehicleType,
        service,
        selectedDeposit ? selectedDeposit.map((item) => item.depositId) : []
      ),
    enabled: ![vehiclePlates, vehicleType, service].includes(null),
  });

  const dates: Moment[] = getDateObject(get(calendarData, "data.Terminy", []));

  const isValidStep = (val = selectedDate) => {
    if(val){
      return dates.find((item) => item.format("DD-MM-YYYY HH:mm") === val.format("DD-MM-YYYY HH:mm"));
    }else {
      return false;
    }
  };

  const nextStep = () => {
    if (isValidStep()) {
      setStep();
    } else {
      setSelectedDateError(true);
    }
  };

  return (
    <div className="lg:max-w-[1440px] lg:mx-auto">
      <div className="text-2xl font-bold text-blueMain my-5 px-4">Wybierz termin</div>
      {calendarDataOther.isFetching && (
        <div className="flex justify-center mt-6 mb-4">
          <span className="loading loading-dots loading-lg text-blueMain"></span>
        </div>
      )}
      {!calendarDataOther.isFetching && calendarData && (
        <div className="border-t border-b border-grayMain bg-white lg:mx-4 lg:border-x lg:rounded-xl">
          {Object.keys(dates).length !== 0 && (
            <Calendar
              selectedDate={selectedDate}
              setSelectedDate={(val) => {
                setSelectedDateError(false);
                setSelectedDate(val);
                if (isValidStep(val)) {
                  setStepFormData("selectedDate", val);
                } else {
                  setStepFormData("selectedDate", undefined);
                }
              }}
              dates={dates}
            />
          )}
        </div>
      )}

      <div className="mt-5 px-4 lg:mt-7 lg:flex lg:flex-col items-end pb-10">
        <Button onClick={nextStep} btnClassName="lg:max-w-[350px] mb-3">
          Przejdź do danych
          <BtnArrowRight stroke={"#FFF"} />
        </Button>
        {selectedDateError && (
          <div className="text-sm text-redMain mb-2">Wybierz date i godzine</div>
        )}

        <div className="text-sm text-textTertiary text-center lg:max-w-[320px]">
          Wybrany termin może odbiegać od faktycznego czasu przyjęcia pojazdu
        </div>
      </div>
    </div>
  );
};
