import { useEffect } from "react";

import infoBanerTireIcon from "../../../assets/tireIcon.svg";
import snowflakeIcon from "../../../assets/snowflake.svg";
import sunIcon from "../../../assets/sun.svg";
import sunsetSnowIcon from "../../../assets/sunset-snow.svg";

import { DepositData } from "../../../types";

interface DepositProps {
  selectedDeposit:
    | { depositNumber: string; depositId: string; tiresCount: number }[]
    | null
    | undefined;
  vehiclePlates: string;
  depositError: boolean;
  setStepFormData: (key: string, val: any) => void;
  setDepositError: (val: boolean) => void;
  depositsPlatesData: null | DepositData[] | undefined;
  isFetching: boolean;
}

export const Deposit = ({
  selectedDeposit,
  setStepFormData,
  depositsPlatesData,
  depositError,
  setDepositError,
  isFetching,
  vehiclePlates,
}: DepositProps) => {
  useEffect(() => {
    if (
      selectedDeposit === undefined &&
      depositsPlatesData !== undefined &&
      !isFetching &&
      vehiclePlates.length >= 6
    ) {
      setDepositError(false);
      if (depositsPlatesData === null) {
        setStepFormData("selectedDeposit", []);
      } else {
        setStepFormData(
          "selectedDeposit",
          depositsPlatesData.length === 1
            ? [
                {
                  depositNumber: depositsPlatesData[0].Numer,
                  depositId: depositsPlatesData[0].ID,
                  tiresCount: depositsPlatesData[0].Pozycje.length,
                },
              ]
            : []
        );
      }
    }
  }, [depositsPlatesData, isFetching]);

  return (
    <>
      {
        <div className="mt-6">
          {isFetching || depositsPlatesData === undefined ? (
            <>
              <div className="flex justify-center mt-6 mb-4">
                <span className="loading loading-dots loading-lg text-blueMain"></span>
              </div>
            </>
          ) : (
            <>
              <div className="label pt-0">
                <span
                  className={`label-text ${
                    depositError &&
                    selectedDeposit?.length === 0 &&
                    (depositsPlatesData === null || depositsPlatesData === undefined)
                      ? " text-redMain"
                      : ""
                  }`}
                >
                  Opony*
                  {depositError &&
                  selectedDeposit?.length === 0 &&
                  (depositsPlatesData === null || depositsPlatesData === undefined)
                    ? " - Wybierz opcje"
                    : ""}
                </span>
              </div>
              {selectedDeposit === undefined ||
              depositsPlatesData === null ||
              depositsPlatesData.length === 0 ? (
                <div
                  className={`border-[1px] border-graySecond bg-grayLight cursor-pointer rounded-xl mb-3`}
                >
                  <div className={`p-4 border-b-[2px] border-graySecond`}>
                    <div className={`flex items-center gap-2`}>
                      <input
                        type="radio"
                        className="radio checked:bg-grayMain checked:border-grayMain h-4 w-4 cursor-not-allowed"
                        onChange={() => {}}
                        checked={false}
                      />
                      <div className="pt-[2px] font-medium">Z depozytu</div>
                    </div>
                  </div>
                  <div className="border-[1px] border-grayMain bg-[#EFF4FF] rounded-xl m-4 flex gap-3 px-2">
                    <img src={infoBanerTireIcon} className="h-[35px] w-[35px] mt-2" alt={""}/>
                    <div className="py-4">
                      <div className="text-[#344054] text-sm font-bold">
                        Brak opon w depozycie dla pojazdu o podanych numerach
                        rejestracyjnych
                      </div>
                      <div className="text-[#475467] text-sm mt-1">
                        Prosimy sprawdzić numer rejestracyjny, jeśli masz depozyt
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`border-[1px] bg-white cursor-pointer rounded-xl mb-3
                    ${
                      selectedDeposit !== null && selectedDeposit.length !== 0
                        ? "border-greenSecond border-[2px]"
                        : "border-grayMain"
                    }      
                    ${
                      depositError ? "!shadow-inputError !border-redBorder" : ""
                    }              
                    `}
                >
                  <div
                    className={`p-4 border-b-[1px] ${
                      selectedDeposit !== null &&
                      !depositError &&
                      selectedDeposit.length !== 0
                        ? "border-greenSecond "
                        : "border-grayMain"
                    }`}
                  >
                    <div className={`flex items-center gap-2`}>
                      <input
                        type="radio"
                        className="radio checked:bg-white checked:border-greenSecond checked:border-[5px] h-4 w-4"
                        onChange={() => {}}
                        checked={selectedDeposit !== null && selectedDeposit.length !== 0}
                      />
                      <div className="pt-[2px] font-medium">Z depozytu</div>
                    </div>
                    {depositError && (
                      <div className="text-sm text-redMain ml-6">
                        Wybierz minimum jeden depozyt z listy lub zaznacz opcję “dostarczę
                        do serwisu”
                      </div>
                    )}
                  </div>
                  {depositsPlatesData.map((depozyt, index) => {
                    const isSelected = selectedDeposit
                      ? selectedDeposit.find((item) => item.depositId === depozyt.ID) !==
                        undefined
                      : false;
                    return (
                      <div
                        key={depozyt.ID}
                        className={`py-4 pl-8 pr-4 md:grid md:grid-cols-3 ${
                          index !== 0 ? "border-t-[1px] border-grayMain" : ""
                        }`}
                        onClick={() => {
                          setStepFormData("selectedDate", undefined);
                          setDepositError(false);
                          if (!isSelected) {
                            setStepFormData("selectedDeposit", [
                              ...(selectedDeposit || []),
                              {
                                depositNumber: depozyt.Numer,
                                depositId: depozyt.ID,
                                tiresCount: depozyt.Pozycje.length,
                              },
                            ]);
                          } else {
                            const filteredDeposit = selectedDeposit?.filter(
                              (item) => item.depositId !== depozyt.ID
                            );
                            if (filteredDeposit?.length === 0) {
                              setStepFormData("selectedDeposit", []);
                            } else {
                              setStepFormData("selectedDeposit", filteredDeposit);
                            }
                          }
                        }}
                      >
                        <div className="flex gap-2">
                          <input
                            type="checkbox"
                            onChange={() => {}}
                            className="checkbox mt-[1px] h-4 w-4 [--chkbg:#66C61C] [--chkfg:#FFF] border-grayMain checked:border-greenSecond rounded-[4px]"
                            checked={isSelected}
                          />
                          <div>
                            <div className="text-sm font-medium text-textMain">
                              Depozyt nr {depozyt.Numer}
                            </div>
                            <div className="text-sm text-textSecondary font-normal">
                              Lokalizajca {depozyt.SerwisNazwa}
                            </div>
                          </div>
                        </div>

                        <div className="mt-4 md:mt-0 pl-6 flex flex-col gap-3 lg:col-span-2">
                          {depozyt.Pozycje.map((item, index) => {
                            return (
                              <div key={index}>
                                <div className="flex gap-2">
                                  {item.Sezon === "Zima" && (
                                    <img src={snowflakeIcon} className="h-4 w-4 mt-1" alt={""}/>
                                  )}
                                  {item.Sezon === "Lato" && (
                                    <img src={sunIcon} className="h-4 w-4  mt-1" alt={""}/>
                                  )}
                                  {item.Sezon === "Całoroczne" && (
                                    <img src={sunsetSnowIcon} className="h-4 w-4 mt-1" alt={""}/>
                                  )}
                                  <div>
                                    <p className="text-sm font-medium text-textMain">
                                      {item.Nazwa}
                                    </p>
                                    <p className="text-sm font-normal text-textSecondary">
                                      Głębokość bieżnika: {item.Bieznik}mm
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div
                className={`border-[1px] p-4 rounded-xl cursor-pointer bg-white ${
                  selectedDeposit === null
                    ? "border-greenSecond border-[2px]"
                    : "border-grayMain"
                }`}
                onClick={() => {
                  if (vehiclePlates.length >= 6) {
                    setDepositError(false);
                    setStepFormData("selectedDate", undefined);
                    setStepFormData("selectedDeposit", null);
                  }
                }}
              >
                <div className="flex gap-2">
                  <input
                    type="radio"
                    className="radio checked:bg-white checked:border-greenSecond checked:border-[5px] h-4 w-4"
                    onChange={() => {}}
                    checked={selectedDeposit === null}
                  />
                  <div>
                    <div className="text-sm font-medium text-[#344054]">
                      Dostarczę do serwisu
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      }
    </>
  );
};
