import axios from "axios";
import moment from "moment";
import download from "downloadjs";

export const fetchServices = (service: number | undefined = undefined) => {
  return axios
      .get(
          `https://rezerwacje.oponyserwis.pl/api/v1/Klient/API_PobierzObslugiwaneTypySamochoduWgSerwisID?serwisID=${service}`
      )
      .then((res) => res.data);
};

export const fetchDepositPlates = (vehiclePlates: string) => {
  return axios
      .get(
          `https://rezerwacje.oponyserwis.pl/api/v1/Common/API_PobierzTypSamochoduWgFirmaGUID?firmaGUID=ec202bf1d622486298a77655b1e6e113&rejestracja=${vehiclePlates}`
      )
      .then((res) => res.data);
};

export const fetchCalendarData = (
    vehiclePlates: string,
    vehicleType: number | null,
    service: number | undefined,
    selectedDeposit: string[]
) => {
  const selectedDepositToSend = selectedDeposit.length == 0 ? "-X" : selectedDeposit[0];

  return axios
      .get(
          `https://rezerwacje.oponyserwis.pl/api/v1/Klient/API_RezerwacjaDostepneTerminy?serwisID=${service}&samochodTypID=${vehicleType}&rejestracja=${vehiclePlates}&wybranyDepozyt=${selectedDepositToSend}`
      )
      .then((res) => res.data);
};

export const getConfirmationPDF = (reservationId: string) => {
  return axios
      .get(
          `https://rezerwacje.oponyserwis.pl/api/v1/Klient/PotwierdzeniePDF?zlecenieGUID=${reservationId}`,
          { responseType: "blob" }
      )
      .then((response) => {
        const content = response.headers["content-type"];
        download(response.data, "potwierdzenie-rezerwacji", content);
      });
};

export const submitForm = (
    vehiclePlates: string,
    vehicleType: number | null,
    service: number | undefined,
    selectedDeposit: { depositNumber: string; depositId: string }[] | null | undefined,
    selectedDate: string | undefined,
    email: string,
    fullName: string,
    phone: string
) => {
  const selectedDepositToSend = selectedDeposit ? selectedDeposit[0].depositNumber : "-X";

  return axios
      .get(
          `https://rezerwacje.oponyserwis.pl/api/v1/Klient/API_RezerwacjaTerminuZWyboremSerwisu?serwisID=${service}&samochodTypID=${vehicleType}&rejestracja=${vehiclePlates}&klientEmail=${email}&klientNazwa=${fullName}&klientTelefon=${phone}&data=${moment(
              selectedDate,
              "DD-MM-YYYY HH:mm"
          ).format("YYYY-MM-DD")}&godzina=${moment(selectedDate, "DD-MM-YYYY HH:mm").format(
              "HH:mm"
          )}&wybranyDepozyt=${selectedDepositToSend}`
      )
      .then((res) => res.data);
};
