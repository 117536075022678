import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import download from "downloadjs";
import { EventAttributes, createEvent } from "ics";

import mapIcon from "../../assets/marker-pin-01.svg";
import carIcon from "../../assets/car-01.svg";
import tireIcon from "../../assets/tyre-line.svg";
import calendarIcon from "../../assets/calendar.svg";
import mapPreview from "../../assets/map.png";
import closeIcon from "../../assets/close.svg";
import appleIcon from "../../assets/apple.svg";
import msOutlookIcon from "../../assets/ms_outlook.svg";
import googleIcon from "../../assets/google.svg";
import downloadIcon from "../../assets/file-download-03.svg";

import serviceBonarka from "../../assets/bonarekService.jpg";
import serviceLegnicka from "../../assets/legnickaSerwis.jpg";

import { getPolishDayName, getPolishMonthName } from "../../utils";
import { getConfirmationPDF } from "../../services";
import { Button } from "../buttons/Button";
import { FormData } from "../../types";

interface SummaryCardProps {
  formData: FormData;
  PDFId: string;
}

export const SummaryCard = ({ formData, PDFId }: SummaryCardProps) => {
  const { selectedDate, selectedDeposit, service, vehiclePlates, vehicleTypeName } =
    formData;
  const momentData = moment(selectedDate, "DD-MM-YYYY HH:mm");
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const { refetch, ...confirmationPDFDataOther } = useQuery({
    queryKey: ["confirmationPDF"],
    queryFn: () => getConfirmationPDF(PDFId),
    enabled: false,
    retry: false,
  });

  const event: EventAttributes = {
    start: [
      +momentData.format("YYYY"),
      +momentData.format("MM"),
      +momentData.format("DD"),
      +momentData.format("HH"),
      +momentData.format("mm"),
    ],
    duration: { hours: 1, minutes: 0 },
    title: `Rezerwacja wymiany opon ${
      service === 6
        ? "Serwis Bonarka (-1 parking) - ul. Kamieńskiego 11, Kraków"
        : "Serwis Legnicka - ul. Legnicka 5, Kraków"
    }`,
  };

  const addToCalendarClick = () => {
    createEvent(event, (_, value) => {
      const blob = new Blob([value], { type: "text/calendar" });
      download(blob, "rezerwacja-serwis-opon-event.ics");
    });
  };

  const getImgHeight = () => {
    switch (true) {
      case selectedDeposit?.length === 2:
        return service === 5 ? "lg:h-[500px]" : "lg:h-[536px]";
      case selectedDeposit?.length === 3:
        return service === 5 ? "lg:h-[530px]" : "lg:h-[566px]";
      case selectedDeposit?.length === 4:
        return service === 5 ? "lg:h-[550px]" : "lg:h-[586px]";
      default:
        return service === 5 ? "lg:h-[490px]" : "lg:h-[526px]";
    }
  };

  return (
    <div className="bg-white rounded-xl flex flex-col overflow-hidden border border-grayMain lg:flex-row w-full">
      <div className="h-full">
        {service === 6 && (
          <img
            src={serviceBonarka}
            className={`w-full object-cover h-[160px] lg:w-[600px] ${getImgHeight()}`}
          />
        )}
        {service === 5 && (
          <img
            src={serviceLegnicka}
            className={`w-full object-cover h-[160px] lg:w-[600px] ${getImgHeight()}`}
          />
        )}
      </div>

      <div className="py-6 px-8 flex flex-col justify-between">
        <div className="space-y-6 mb-5 lg:mb-0">
          <div className="flex gap-3 items-start">
            <img src={calendarIcon} className="pt-[2px]" />
            <div>
              <div className="font-semibold text-2xl">
                {moment(selectedDate, "DD-MM-YYYY HH:mm").format("D")}{" "}
                {getPolishMonthName(
                  +moment(selectedDate, "DD-MM-YYYY HH:mm").format("M")
                )}{" "}
                - {moment(selectedDate, "DD-MM-YYYY HH:mm").format("HH:mm")}{" "}
                <span className=" text-base">
                  {"(" +
                    getPolishDayName(
                      +moment(selectedDate, "DD-MM-YYYY HH:mm").day() - 1
                    ) +
                    ")"}
                </span>
              </div>
              <div className="text-sm text-textTertiary w-[280px] mt-1">
                Wybrany termin może odbiegać od faktycznego czasu przyjęcia pojazdu
              </div>

              <div className=" font-medium mt-5 mb-2">Dodaj do kalendarza</div>
              <div className="grid grid-cols-3 gap-2">
                <div
                  onClick={() => addToCalendarClick()}
                  className="cursor-pointer border-grayMain border flex items-center justify-center h-[48px] rounded-lg rounded-tl-xl rounded-br-xl lg:hover:bg-grayLight"
                >
                  <img src={googleIcon} />
                </div>
                <div
                  onClick={() => addToCalendarClick()}
                  className="cursor-pointer border-grayMain border flex items-center justify-center h-[48px] rounded-lg rounded-tl-xl rounded-br-xl lg:hover:bg-grayLight"
                >
                  <img src={appleIcon} />
                </div>
                <div
                  onClick={() => addToCalendarClick()}
                  className="cursor-pointer border-grayMain border flex items-center justify-center h-[48px] rounded-lg rounded-tl-xl rounded-br-xl lg:hover:bg-grayLight"
                >
                  <img src={msOutlookIcon} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-3 items-start">
            <img src={mapIcon} />
            <div className="flex justify-between w-full items-start">
              {service === 6 && (
                <div>
                  <div className="font-medium text-textMain">
                    Serwis Bonarka (-1 parking)
                  </div>
                  <div className="text-sm text-textSecondary">
                    ul. Kamieńskiego 11 <br /> Kraków
                  </div>
                  <div
                    className="underline text-blueMain mt-3 cursor-pointer"
                    onClick={() => setIsViewerOpen(true)}
                  >
                    Mapa dojazdu
                  </div>
                </div>
              )}

              {service === 5 && (
                <div>
                  <div className="font-medium text-textMain">Serwis Legnicka</div>
                  <div className="text-sm text-textSecondary">
                    ul. Legnicka 5 <br /> Kraków
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex gap-3 font-medium">
            <img src={carIcon} />
            <span>
              {vehiclePlates} ({vehicleTypeName})
            </span>
          </div>
          <div className="flex gap-3 font-medium items-start">
            <img src={tireIcon} />
            <div className="flex flex-col">
              {selectedDeposit ? (
                <>
                  {selectedDeposit.map((item) => (
                    <div>{`Depozyt nr ${item.depositNumber}`}</div>
                  ))}
                </>
              ) : (
                "Dostarczę do serwisu"
              )}
            </div>
          </div>
        </div>

        <Button
          onClick={() => {
            refetch();
          }}
        >
          {confirmationPDFDataOther.isFetching ? (
            <div className="flex justify-center">
              <span className="loading loading-dots loading-md text-white"></span>
            </div>
          ) : (
            <div className="flex justify-center items-center gap-2">
              <img src={downloadIcon} />
              <div>Pobierz potwierdzenie</div>
            </div>
          )}
        </Button>
      </div>
      {isViewerOpen && (
        <ImageViewer
          src={[mapPreview]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setIsViewerOpen(false)}
          closeComponent={
            <img
              src={closeIcon}
              onClick={(e) => {
                e.stopPropagation();
                setIsViewerOpen(false);
              }}
            />
          }
        />
      )}
    </div>
  );
};
