import React from "react";
interface ButtonProps {
  btnClassName?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}
export const Button = ({ btnClassName, children, onClick }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`cursor-pointer rounded-lg rounded-tl-2xl rounded-br-2xl py-3 w-full bg-greenMain lg:hover:bg-greenHover text-white flex justify-center items-center text-md font-bold gap-[6px] ${btnClassName}`}
    >
      {children}
    </button>
  );
};
