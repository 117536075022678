import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { DropDownInput } from "../../../components/input/DropDownInput";
import { fetchServices } from "../../../services";
import { Input } from "../../../components/input/Input";

interface CarInfoProps {
  service: number | undefined;
  vehicleTypeError: boolean;
  vehiclePlatesError: boolean;
  vehicleType: number | null;
  vehicleTypeName: string | null;
  vehiclePlates: string;
  setVehicleTypeError: (val: boolean) => void;
  setVehiclePlatesError: (val: boolean) => void;
  setStepFormData: (key: string, val: any) => void;
  depositsPlatesRefetch: () => void;
}

export const CarInfo = ({
  vehicleTypeError,
  vehicleType,
  vehicleTypeName,
  setVehicleTypeError,
  setStepFormData,
  service,
  vehiclePlates,
  setVehiclePlatesError,
  vehiclePlatesError,
  depositsPlatesRefetch,
}: CarInfoProps) => {
  const [type, setType] = useState<null | { value: number; label: string }>(null);
  const { data: servicesData, ...servicesDataOther } = useQuery<{
    data: { ID: number; Nazwa: string }[];
  }>({
    queryKey: ["services", { service }],
    queryFn: () => fetchServices(service),
    enabled: service !== undefined,
  });
  const getDropDownValueIfExist = () => {
    if (servicesData !== undefined) {
      const tempType = servicesData.data.find(
        (item) => item.ID === vehicleType && item.Nazwa === vehicleTypeName
      );
      if (tempType !== undefined) {
        setStepFormData("vehicleTypeName", tempType.Nazwa);
        setStepFormData("vehicleType", tempType.ID);
        return { value: tempType.ID, label: tempType.Nazwa };
      } else {
        setStepFormData("vehicleTypeName", null);
        setStepFormData("vehicleType", null);
        return null;
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (servicesData !== undefined) {
      setType(getDropDownValueIfExist());
    }
  }, [service]);

  const debounced = useDebouncedCallback(() => {
    if (vehiclePlates.length >= 6 && vehicleType !== null) {
      depositsPlatesRefetch();
    }
  }, 1000);

  return (
    <div className="mt-6">
      <div className="font-bold text-blueMain text-3xl">Informacje o pojezdzie</div>
      <div className="flex flex-col gap-5 lg:flex-row lg:gap-8 mt-4">
        <DropDownInput
          isLoading={servicesDataOther.isFetching || servicesData === undefined}
          label="Typ pojazdu*"
          errorMessage="Wybierz typ pojazdu"
          isError={vehicleTypeError}
          value={type}
          onChange={(newValue) => {
            setVehicleTypeError(false);
            setStepFormData("selectedDate", undefined);
            setStepFormData("vehicleTypeName", newValue !== null ? newValue.label : null);
            setStepFormData("vehicleType", newValue !== null ? newValue.value : null);
            if (newValue !== null && newValue.value && newValue.label) {
              setType({ value: newValue.value, label: newValue.label });
              debounced();
            }
          }}
          options={
            servicesData !== undefined
              ? servicesData.data.map((item: { ID: number; Nazwa: string }) => ({
                  label: item.Nazwa,
                  value: item.ID,
                }))
              : []
          }
          placeholder="Wybierz"
        />
        <Input
          type="text"
          autocorrect="off"
          autocapitalize="off"
          autocomplete="off"
          label="Numer rejestracyjny pojazdu*"
          value={vehiclePlates}
          onChange={(e) => {
            setStepFormData("vehiclePlates", e.target.value);
            setStepFormData("selectedDeposit", undefined);
            if (e.target.value.length < 6) {
              setVehiclePlatesError(true);
            } else {
              setVehiclePlatesError(false);
              debounced();
            }
          }}
          placeholder="np. KRK7833"
          isError={vehiclePlatesError}
          errorMessage={
            vehiclePlates === ""
              ? "Wpisz numer rejestracyjny pojazdu"
              : "Wpisz numer rejestracyjny (min. 6 znaków)"
          }
        />
      </div>
    </div>
  );
};
