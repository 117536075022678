import confirmIcon from "../../assets/step-icon-base.svg";
import { SummaryCard } from "../../components/cards/SummaryCard";
import { FormData } from "../../types";

interface ConfirmationStepProps {
  formData: FormData;
  PDFId: string;
}
export const ConfirmationStep = ({ formData, PDFId }: ConfirmationStepProps) => {
  return (
    <div className="p-3 pt-8 lg:max-w-[1440px] lg:mx-auto flex flex-col ">
      <div className="flex flex-col justify-center items-center px-4 mb-8">
        <img src={confirmIcon} className="w-[52px] h-[52px] mb-6" />
        <div className="text-3xl text-blueMain font-bold text-center max-w-[500px] ">
          Zarezerwowaliśmy dla Ciebie stanowisko serwisowe
        </div>
        <div className="text-sm text-[#667085] text-center max-w-[250px] font-medium">
          Potwierdzenie przesłaliśmy email oraz SMS na Twoje dane.
        </div>
      </div>
      <div className="mb-10 mx-auto max-w-[400px] w-full lg:max-w-[1000px]">
        <SummaryCard formData={formData} PDFId={PDFId} />
      </div>
    </div>
  );
};
