import { useState } from "react";
import ImageViewer from "react-simple-image-viewer";

import closeIcon from "../../assets/close.svg";
interface ServiceCardProps {
  img: any;
  checked: boolean;
  onClick: () => void;
  isError: boolean;
  serviceName: string;
  address: string;
  map?: string;
  openingHours: OpeningHours;
}

interface OpeningHours {
  normal: string;
  weekend: string;
}

export const ServiceCard = ({
  img,
  checked,
  onClick,
  isError,
  serviceName,
  address,
  map,
  openingHours
}: ServiceCardProps) => {
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  return (
    <div
      onClick={onClick}
      className={`bg-white relative rounded-xl overflow-hidden border-[1px] lg:flex w-full ${
        isError
          ? "border-redBorder"
          : checked
          ? " border-greenSecond shadow-selectedCard lg:hover:shadow-none lg:hover:border-grayDark"
          : "border-grayMain lg:hover:border-grayDark"
      }`}
    >
      <input
        onChange={() => {}}
        type="radio"
        className="absolute top-6 lg:top-4 right-4 radio checked:bg-white bg-white checked:border-greenSecond checked:border-[6px] h-5 w-5"
        checked={checked}
      />
      <div className="">
        <img src={img} className="w-full object-cover h-[160px] lg:w-[150px] lg:h-full" />
      </div>
      <div className="p-4 pt-3 lg:w-[calc(100%-150px)]">
        <div>
          <div className="font-semibold">{serviceName}</div>
          <div className="text-sm text-textSecondary">{address}</div>
        </div>
        <div className="flex justify-between items-end mt-3 w-full">
          <div className="text-sm text-textSecondary">
            {openingHours.normal}
            <br />
            {openingHours.weekend}
          </div>
          {map && (
            <div>
              <div
                className="underline text-blueMain cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsViewerOpen(true);
                }}
              >
                Mapa dojazdu
              </div>
            </div>
          )}
        </div>
      </div>
      {isViewerOpen && map && (
        <ImageViewer
          src={[map]}
          currentIndex={0}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={() => setIsViewerOpen(false)}
          closeComponent={
            <img
              src={closeIcon}
              onClick={(e) => {
                e.stopPropagation();
                setIsViewerOpen(false);
              }}
            />
          }
        />
      )}
    </div>
  );
};
