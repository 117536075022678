import moment from "moment";

import serviceBonarka from "../../assets/bonarekService.jpg";
import serviceLegnicka from "../../assets/legnickaSerwis.jpg";
import mapIcon from "../../assets/marker-pin-01.svg";
import carIcon from "../../assets/car-01.svg";
import tireIcon from "../../assets/tyre-line.svg";
import calendarIcon from "../../assets/calendar.svg";

import { getPolishMonthName } from "../../utils";
import { FormData } from "../../types";

interface SmallSummaryCardProps {
  formData: Partial<FormData>;
}

export const SmallSummaryCard = ({ formData }: SmallSummaryCardProps) => {
  const { selectedDate, selectedDeposit, service, vehiclePlates, vehicleTypeName } =
    formData;

  return (
    <div className="bg-white border-grayMain border p-6 w-[380px] rounded-xl space-y-5">
      {service && (
        <div className="flex gap-3 items-start">
          <img src={mapIcon} />
          <div className="flex justify-between w-full items-start">
            {service === 6 && (
              <>
                <div>
                  <div className="font-medium text-textMain">Bonarka</div>
                  <div className="text-sm text-textSecondary mt-1">
                    ul. Kamieńskiego 11 <br /> Kraków
                  </div>
                </div>
                <div>
                  <img src={serviceBonarka} className="h-16" />
                </div>
              </>
            )}
            {service === 5 && (
              <>
                <div>
                  <div className="font-medium text-textMain">Legnicka</div>
                  <div className="text-sm text-textSecondary mt-1">
                    ul. Legnicka 5 <br /> Kraków
                  </div>
                </div>
                <div>
                  <img src={serviceLegnicka} className="h-16" />
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {vehiclePlates && vehicleTypeName && (
        <div className="flex gap-3 font-medium">
          <img src={carIcon} />
          <span>
            {vehiclePlates} ({vehicleTypeName})
          </span>
        </div>
      )}

      {selectedDeposit !== undefined &&
        selectedDeposit?.length !== 0 &&
        vehiclePlates &&
        vehicleTypeName && (
          <div className="flex gap-3 font-medium items-start">
            <img src={tireIcon} />
            <div>
              {selectedDeposit ? (
                <>
                  {selectedDeposit.map((item) => (
                    <div key={item.depositId}>{`Depozyt nr ${item.depositNumber}`}</div>
                  ))}
                </>
              ) : (
                "Dostarczę do serwisu"
              )}
            </div>
          </div>
        )}

      {selectedDate && (
        <div className="flex gap-3 items-start">
          <img src={calendarIcon} />
          <div>
            <div className="font-semibold text-2xl">
              {moment(selectedDate, "DD-MM-YYYY HH:mm").format("D")}{" "}
              {getPolishMonthName(+moment(selectedDate, "DD-MM-YYYY HH:mm").format("M"))}{" "}
              - {moment(selectedDate, "DD-MM-YYYY HH:mm").format("HH:mm")}
            </div>
            <div className="text-sm text-textTertiary w-[280px]">
              Wybrany termin może odbiegać od faktycznego czasu przyjęcia pojazdu
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
