import doneIcon from "../../assets/Tick.svg";

interface StepsIndicatorProps {
  step: number;
  setStep: (val: number) => void;
}

export const StepsIndicator = ({ step, setStep }: StepsIndicatorProps) => {
  return (
    <div className={`w-full flex items-center mt-5 lg:mt-0 gap-1 lg:gap-3 `}>
      {/* Desktop nav */}
      <div
        className={`hidden lg:flex items-center gap-3 cursor-pointer`}
        onClick={() => setStep(1)}
      >
        {step === 1 ? (
          <div className="h-8 w-8 bg-greenMain shadow-currentStep rounded rounded-tl-lg rounded-br-lg flex justify-center items-center">
            <div className="w-[10px] h-[10px] rounded-full bg-white"></div>
          </div>
        ) : step > 1 ? (
          <div className="h-8 w-8 bg-greenMain shadow-currentStep rounded rounded-tl-lg rounded-br-lg flex justify-center items-center">
            <img src={doneIcon} className="w-4" />
          </div>
        ) : (
          <div className="h-8 w-8 bg-grayLight border-2 border-graySecond rounded rounded-tl-lg rounded-br-lg flex justify-center items-center">
            <div className="w-[10px] h-[10px] rounded-full bg-grayMain"></div>
          </div>
        )}
        <div
          className={`font-semibold ${step === 1 ? "text-greenHover" : "text-textMain"}`}
        >
          Serwisy
        </div>
      </div>
      <div className="h-1 w-6 bg-graySecond hidden lg:block"></div>
      <div
        className={`hidden lg:flex items-center gap-3 cursor-pointer`}
        onClick={() => setStep(2)}
      >
        {step === 2 ? (
          <div className="h-8 w-8 bg-greenMain shadow-currentStep rounded rounded-tl-lg rounded-br-lg flex justify-center items-center">
            <div className="w-[10px] h-[10px] rounded-full bg-white"></div>
          </div>
        ) : step > 2 ? (
          <div className="h-8 w-8 bg-greenMain shadow-currentStep rounded rounded-tl-lg rounded-br-lg flex justify-center items-center">
            <img src={doneIcon} className="w-4" />
          </div>
        ) : (
          <div className="h-8 w-8 bg-grayLight border-2 border-graySecond rounded rounded-tl-lg rounded-br-lg flex justify-center items-center">
            <div className="w-[10px] h-[10px] rounded-full bg-grayMain"></div>
          </div>
        )}
        <div
          className={`font-semibold ${step === 2 ? "text-greenHover" : "text-textMain"}`}
        >
          Terminy
        </div>
      </div>
      <div className="h-1 w-6 bg-graySecond hidden lg:block"></div>
      <div
        className={`hidden lg:flex gap-3 items-center cursor-pointer`}
        onClick={() => setStep(3)}
      >
        {step === 3 ? (
          <div className="h-8 w-8 bg-greenMain shadow-currentStep rounded rounded-tl-lg rounded-br-lg flex justify-center items-center">
            <div className="w-[10px] h-[10px] rounded-full bg-white"></div>
          </div>
        ) : step > 3 ? (
          <div className="h-8 w-8 bg-greenMain shadow-currentStep rounded rounded-tl-lg rounded-br-lg flex justify-center items-center">
            <img src={doneIcon} className="w-4" />
          </div>
        ) : (
          <div className="h-8 w-8 bg-grayLight border-2 border-graySecond rounded rounded-tl-lg rounded-br-lg flex justify-center items-center">
            <div className="w-[10px] h-[10px] rounded-full bg-grayMain"></div>
          </div>
        )}
        <div
          className={`font-semibold ${step === 3 ? "text-greenHover" : "text-textMain"}`}
        >
          Dane
        </div>
      </div>
      <div className="h-1 w-6 bg-graySecond hidden lg:block"></div>
      <div className={`hidden lg:flex gap-3 items-center cursor-pointer`}>
        {step === 4 ? (
          <div className="h-8 w-8 bg-greenMain shadow-currentStep rounded rounded-tl-lg rounded-br-lg flex justify-center items-center">
            <div className="w-[10px] h-[10px] rounded-full bg-white"></div>
          </div>
        ) : step > 4 ? (
          <div className="h-8 w-8 bg-greenMain shadow-currentStep rounded rounded-tl-lg rounded-br-lg flex justify-center items-center">
            <img src={doneIcon} className="w-4" />
          </div>
        ) : (
          <div className="h-8 w-8 bg-grayLight border-2 border-graySecond rounded rounded-tl-lg rounded-br-lg flex justify-center items-center">
            <div className="w-[10px] h-[10px] rounded-full bg-grayMain"></div>
          </div>
        )}
        <div
          className={`font-semibold ${step === 4 ? "text-greenHover " : "text-textMain"}`}
        >
          Potwierdzenie
        </div>
      </div>
      {/* Mobile nav */}
      <button
        onClick={() => setStep(1)}
        className={`border-t-[4px] text-left lg:hidden flex-grow pt-2 font-semibold text-xs sm:text-base ${
          step === 1
            ? "animate-fadeInBorder text-greenHover font-bold"
            : step > 1
            ? "border-greenSecond"
            : "border-graySecond"
        }`}
      >
        Serwisy
      </button>
      <button
        onClick={() => setStep(2)}
        className={`border-t-[4px] text-left lg:hidden flex-grow pt-2 font-semibold text-xs sm:text-base ${
          step === 2
            ? "animate-fadeInBorder text-greenHover"
            : step > 2
            ? "border-[#66C61C]"
            : "border-[#EAECF0]"
        }`}
      >
        Terminy
      </button>
      <button
        onClick={() => setStep(3)}
        className={`border-t-[4px] text-left lg:hidden flex-grow pt-2 font-semibold text-xs sm:text-base ${
          step === 3
            ? "animate-fadeInBorder text-greenHover "
            : step > 3
            ? "border-[#66C61C]"
            : "border-[#EAECF0]"
        }`}
      >
        Dane
      </button>
      <button
        className={`border-t-[4px] text-left lg:hidden flex-grow pt-2 font-semibold text-xs sm:text-base ${
          step === 4
            ? "animate-fadeInBorder text-greenHover "
            : step > 4
            ? "border-[#66C61C]"
            : "border-[#EAECF0]"
        }`}
      >
        Potwierdzenie
      </button>
    </div>
  );
};
