import React from "react";

interface InputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  value: string;
  label: string;
  placeholder: string;
  isError: boolean;
  errorMessage: string;
  type: string;
  autocorrect: string;
  autocomplete: string;
  autocapitalize: string;
  defaultValue?: string;
}

export const Input = ({
  onChange,
  onBlur,
  value,
  isError,
  errorMessage,
  label,
  placeholder,
  type,
  autocomplete,
  autocorrect,
  autocapitalize,
  defaultValue,
}: InputProps) => {
  return (
    <div className="w-full">
      <label className="form-control w-full">
        <div className="label pt-0">
          <span className="label-text">{label}</span>
        </div>
        <input
          onBlur={onBlur}
          defaultValue={defaultValue}
          autoCapitalize={autocapitalize}
          autoComplete={autocomplete}
          autoCorrect={autocorrect}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`input input-bordered w-full h-11 !outline-none  ${
            isError
              ? "focus:shadow-inputError focus:border-redBorder border-redBorder"
              : "focus:shadow-input focus:border-greenMain"
          }`}
        />
      </label>
      {isError && <div className="text-sm text-redMain mt-2">{errorMessage}</div>}
    </div>
  );
};
