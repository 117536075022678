import moment from "moment";

export const getPolishMonthName = (monthNumber: number) => {
  const months = [
    "Styczeń",
    "Luty",
    "Marzec",
    "Kwiecień",
    "Maj",
    "Czerwiec",
    "Lipiec",
    "Sierpień",
    "Wrzesień",
    "Październik",
    "Listopad",
    "Grudzień",
  ];

  if (monthNumber >= 1 && monthNumber <= 12) {
    return months[monthNumber - 1];
  } else {
    return "Nieprawidłowy numer miesiąca";
  }
};

export const getPolishDayName = (day: number) => {
  const days = [
    "Poniedziałek",
    "Wtorek",
    "Środa",
    "Czwartek",
    "Piątek",
    "Sobota",
    "Niedziela",
  ];
  return days[day];
};

export const getDateObject = (dates: any) => {
  return dates.map((item: string) =>
    moment(parseInt(item.replace("/Date(", "").replace(")/", "")))
  );
};

export const getIsValidDepositPlates = (
  deposit:
    | { depositNumber: string; depositId: string; tiresCount: number }[]
    | null
    | undefined
) => {
  if (deposit === undefined) {
    return false;
  }
  if (deposit === null) {
    return true;
  }
  return deposit.length > 0;
};
