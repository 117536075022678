import moment, {Moment} from "moment";
import {useState} from "react";

import {CalendarArrowLeft, CalendarArrowRight} from "../../assets/icons";
import calendarSeparatorBorder from "../../assets/line-7.svg";
import emptyDates from "../../assets/emptyDates.svg";

import {DateCircle} from "./DateCircle";
import {getPolishMonthName} from "../../utils";
import {Button} from "../buttons/Button";

interface CalendarProps {
    setSelectedDate: (val: Moment) => void;
    selectedDate: Moment | undefined;
    dates: Moment[];
}

export const Calendar = ({selectedDate, setSelectedDate, dates}: CalendarProps) => {
    const [dateInView, setDateInView] = useState(moment());

    const getAvailableHoursForData = (data: Moment, granularity: moment.unitOfTime.StartOf = 'day') => {
        return dates.filter(
            (item) => item.isSame(data, granularity)
        ).map((item) => item.format("HH:mm"));
    }

    const getPrevDays = () => {
        const startOfMonth = dateInView.clone().startOf("month").day();

        return [...Array((startOfMonth === 0 ? 7 : startOfMonth) - 1).keys()]
            .reverse()
            .map((item) => {
                const prevMonth = dateInView.clone()
                    .subtract(1, "M")
                    .endOf("month");
                return prevMonth.subtract(item, "d").format("D");
            });
    };

    const getNextDays = () => {
        const endOfMonth = dateInView.clone().endOf("month").day();

        return [...Array(7 - +endOfMonth).keys()].map((item) => {
            const prevMonth = dateInView.clone()
                .add(1, "M")
                .startOf("month");
            return prevMonth.add(item, "d").format("D");
        });
    };

    const getHasPrevDates = () => {
        return dates.filter((item) => {
            return item.isBefore(dateInView.clone().subtract(1, 'month'), 'month') || item.isSame(dateInView.clone().subtract(1, 'month'), 'month')
        }).length > 0
    }

    const getHasNextDates = () => {
        return dates.filter((item) => {
            return item.isAfter(dateInView.clone().add(1, 'month'), 'month') || item.isSame(dateInView.clone().add(1, 'month'), 'month')
        }).length > 0
    }

    const getClosestDate = (datesArray: Moment[], target: Moment) => {
        if (datesArray.length === 0) return moment();

        return datesArray.reduce((closest, currentDate) => {
            const currentDiff = Math.abs(moment(currentDate).diff(target));
            const closestDiff = Math.abs(moment(closest).diff(target));
            return currentDiff < closestDiff ? currentDate : closest;
        });
    };

    return (
        <div className="flex flex-col items-center lg:flex-row lg:items-start">
            <div className="pt-3 pb-3 w-full max-w-[400px] lg:p-9 lg:min-w-[450px] lg:max-w-[450px]">
                <div className="flex justify-between items-center mb-2 h-9 py-2 px-4">
                    <div
                        className="h-5 w-5 cursor-pointer"
                        onClick={() => {
                            if (getHasPrevDates()) {
                                setDateInView(dateInView.clone().subtract(1, "M"));
                            }
                        }}
                    >
                        <CalendarArrowLeft stroke={`${getHasPrevDates() ? "#344054" : "#98A2B3"}`}/>
                    </div>
                    <div className="text-textMain font-bold">
                        {getPolishMonthName(+dateInView.clone().format("M"))} {dateInView.clone().format("YYYY")}
                    </div>
                    <div
                        className="h-5 w-5 cursor-pointer"
                        onClick={() => {
                            if (getHasNextDates()) {
                                setDateInView(dateInView.clone().add(1, "M"));
                            }
                        }}
                    >
                        <CalendarArrowRight stroke={`${getHasNextDates() ? "#344054" : "#98A2B3"}`}/>
                    </div>
                </div>
                <div className="calendar grid grid-cols-7 gap-2 w-full">
                    {["Pon", "Wt", "Śr", "Czw", "Pt", "Sob", "Nd"].map((item, index) => {
                        return (
                            <div
                                key={"week-days-" + index}
                                className=" justify-self-center text-center p-2 w-10 h-10"
                            >
                                {item}
                            </div>
                        );
                    })}
                    {getPrevDays().map((item, index) => {
                        return (
                            <>
                                <DateCircle
                                    onClick={(val) => {
                                        if (getHasPrevDates()) {
                                            setDateInView(dateInView.clone().subtract(1, "month"));
                                            setSelectedDate(dateInView.clone().subtract(1, "month").date(+val))
                                        }
                                    }}
                                    day={item}
                                    key={"prev-" + index}
                                    isActive={getAvailableHoursForData(dateInView.clone().subtract(1, 'month').date(+item)).length > 0}
                                    isCurrent={false}
                                    isSelected={!!selectedDate && selectedDate.clone().isSame(dateInView.clone().subtract(1, 'month').date(+item), 'day')}
                                    isDisabled={true}
                                />
                            </>
                        );
                    })}
                    {Array.from(
                        {length: moment(dateInView.clone(), "DD-MM-YYYY HH:mm").daysInMonth()},
                        (_, i) => i + 1
                    ).map((item, index) => {
                        return (
                            <>
                                <DateCircle
                                    onClick={(val) => {
                                        setSelectedDate(
                                            dateInView.clone().set("date", +val)
                                        );
                                    }}
                                    day={item.toString()}
                                    key={"current-" + index}
                                    isActive={getAvailableHoursForData(dateInView.clone().set("date", item)).length !== 0}
                                    isSelected={!!selectedDate && selectedDate.clone().isSame(dateInView.clone().date(item), 'day')}
                                    isCurrent={
                                        moment().format("D") === item.toString() &&
                                        dateInView.clone().format("M") === moment().format("M")
                                    }
                                />
                            </>
                        );
                    })}
                    {getNextDays().map((item, index) => {
                        return (
                            <>
                                <DateCircle
                                    onClick={(val) => {
                                        if (getHasNextDates()) {
                                            setDateInView(dateInView.clone().add(1, "month"));
                                            setSelectedDate(dateInView.clone().add(1, "month").date(+val))
                                        }
                                    }}
                                    day={item}
                                    key={"next-" + index}
                                    isActive={getAvailableHoursForData(dateInView.clone().add(1, 'month').date(+item)).length > 0}
                                    isCurrent={false}
                                    isSelected={!!selectedDate && selectedDate.clone().isSame(dateInView.clone().add(1, 'month').date(+item), 'day')}
                                    isDisabled={true}
                                />
                            </>
                        );
                    })}
                </div>
            </div>

            <img src={calendarSeparatorBorder} className="hidden lg:block h-full" alt={""}/>

            {(selectedDate && getAvailableHoursForData(selectedDate).length > 0) ? (
                    <div className="border-t border-grayMain py-5 w-full lg:border-0 lg:p-10 lg:max-w-full">
                        <div className="flex flex-col items-center">
                            <div className="max-w-[600px] w-full lg:max-w-full">
                                <div className="font-bold pl-4 lg:pl-0 lg:mb-6">Wybierz godzinę</div>
                                <div
                                    className="flex gap-3 mt-3 no-scrollbar overflow-y-auto cursor-pointer lg:flex-wrap px-4">
                                    {getAvailableHoursForData(selectedDate).map((item) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    setSelectedDate(
                                                        selectedDate.clone()
                                                            .set("hour", +item.split(":")[0])
                                                            .set("minute", +item.split(":")[1])
                                                    );
                                                }}
                                                key={"hour-" + item}
                                                className={`rounded-lg px-5 py-2 font-bold lg:hover:opacity-70 ${
                                                    selectedDate.clone().format("HH:mm") === item
                                                        ? "bg-blueMain text-white "
                                                        : "text-textMain border border-grayMain"
                                                }`}
                                            >
                                                {item}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                ) :
                <div
                    className="border-t border-grayMain py-5 px-3 w-full lg:border-0 lg:p-10 lg:max-w-full flex items-center justify-center flex-col gap-4 lg:mt-24">
                    <div
                        className="h-12 w-12 flex shadow-summaryBtn rounded-lg justify-center items-center bg-white border border-graySecond">
                        <img src={emptyDates} alt={''}/>
                    </div>
                    <div className="text-center">
                        <div className="font-semibold">Brak terminów w tym dniu</div>
                        <div className="text-textSecondary text-sm">
                            Nie ma już dostępnych terminów wybierz inny termin
                        </div>
                        <Button
                            onClick={() => {
                                setSelectedDate(getClosestDate(dates, selectedDate || moment()));
                            }}
                            btnClassName="!bg-blueMain lg:hover:!bg-blueMainHover mt-6"
                        >
                            Pokaż najbliższy wolny
                        </Button>
                    </div>
                </div>
            }
        </div>
    );
};
